import { ReactNode, useEffect } from 'react';
import { env } from '@automata/utils';
import useUser from 'hooks/useUserV2';
import {
  FlagProvider,
  IConfig as UnleashConfig,
  useUnleashContext,
} from '@automata/utils/hooks';

interface Props {
  children: ReactNode;
}

export const unleashConfig: UnleashConfig = {
  url: env('NEXT_PUBLIC_UNLEASH_URL'),
  refreshInterval: 15,
  appName: env('NEXT_PUBLIC_APP_NAME'),
  clientKey: env('NEXT_PUBLIC_UNLEASH_CLIENT_KEY'),
  environment: env('NEXT_PUBLIC_ENV'),
};

export const UnleashProvider = ({ children }: Props): JSX.Element => (
  <FlagProvider config={unleashConfig}>
    <UnleashContextUpdater />
    {children}
  </FlagProvider>
);

export const UnleashContextUpdater = (): null => {
  const { data: user } = useUser();
  const updateContext = useUnleashContext();

  useEffect(() => {
    if (!user?.userData) return;
    if (!user?.currentOrganization) return;

    updateContext({
      userId: user?.userData?.user_id,
      properties: {
        userEmail: user.userData.email,
        organizationName: user.currentOrganization.name,
        organizationId: user.currentOrganization.id,
      },
    });
  }, [updateContext, user?.currentOrganization, user?.userData]);

  return null;
};
