import Box from '@mui/material/Box';
import { Nav } from 'components/Nav';
import { Paper } from 'components/Paper';
import { MaintenanceBanner } from 'components/MaintenanceBanner';
import { useReleaseDiscoverer } from 'hooks/useReleaseDiscoverer';
import { styled } from '@mui/material/styles';
import { UpcomingMaintenanceBanner } from 'components/UpcomingMaintenanceBanner';
import { useFlag, useVariant } from '@automata/utils/hooks';

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  navContainer: {
    flexShrink: 0,
  },
  mainContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
};

interface LayoutProps {
  children: React.ReactNode;
}

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

export const Layout = (props: LayoutProps): JSX.Element => {
  const { children } = props;
  useReleaseDiscoverer();
  const isInMaintenanceMode = useFlag('maintenance_mode');
  const { enabled: isUpcomingMaintenanceMode = false } = useVariant(
    'fe.informational_maintenance_update_scheduled_for'
  );

  return (
    <Paper bgVariant="paper">
      <Box sx={styles.mainContainer}>
        <Box sx={styles.navContainer}>
          <Nav />
          <Offset />
          {isInMaintenanceMode && <MaintenanceBanner />}
          {isUpcomingMaintenanceMode && <UpcomingMaintenanceBanner />}
        </Box>
        <Box sx={styles.mainContentContainer}>{children}</Box>
      </Box>
    </Paper>
  );
};
