import {
  IVariant,
  // eslint-disable-next-line no-restricted-imports
  useFlag as useFlagUnleash,
  useVariant as useVariantUnleash,
} from '@unleash/proxy-client-react';

export {
  FlagProvider,
  useUnleashContext,
  type IConfig,
} from '@unleash/proxy-client-react';

export const activeFlags = [
  'fe.dark_mode',
  'fe.feedback',
  'fe.maestro_workflow',
  'fe.reason',
  'hub.transitions.v1',
  'maintenance_mode',
  'fe.live_run_banner',
  'fe.run_awareness_gantt',
  'fe.run_params',
  'fe.run_params_file_support',
  'fe.informational_maintenance_update_scheduled_for',
  'fe.evals',
  'fe.replan_overlay',
  'fe.tags',
  'fe.cue_workcell',
  'fe.custom_maestro_version',
  'fe.canvas-builder-dev-tools',
  'fe.canvas-builder',
  'fe.canvas-builder-workflow-errors-visible',
  'fe.canvas_data_export_task',
  'fe.abort_labware',
] as const;

export type ActiveFlag = (typeof activeFlags)[number];

export const useFlag = (name: ActiveFlag): boolean | undefined =>
  useFlagUnleash(name);

export const useVariant = (name: ActiveFlag): Partial<IVariant> =>
  useVariantUnleash(name);

export { useFlagsStatus } from '@unleash/proxy-client-react';
